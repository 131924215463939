export const ARBITT = {
  description: "Ayudamos a centros y empresas a procesar, gestionar y colaborar tipos de solicitudes legales y reclamaciones.",
  title: "Arbitt : La solución para tus conflictos.",
  url: "https://arbitt.io",
  sitename: "Arbitt",
  image: "https://" + process.env.VUE_APP_API_DOMAIN + "public/images/arbitt-logo-white.jpg"
};
export const METAS_PER_PAGE = (domain) => {
  if(!domain) return ARBITT_METAS_DEFAULT;
};

export const ARBITT_METAS_DEFAULT = [
  { name: 'description', content: ARBITT.description},
  { property: 'og:title', content: ARBITT.title },
  { property: 'og:description', content: ARBITT.description },
  { property: 'og:image', content: ARBITT.image },
  { property: 'og:url', content: ARBITT.url },
  { property: 'og:type', content: 'website' },
  { property: 'twitter:card', content: 'summary_large_image' },
  { property: 'twitter:site', content: ARBITT.title },
  { property: 'twitter:title', content: ARBITT.title },
  { property: 'twitter:description', content: ARBITT.description },
  { property: 'twitter:image', content: ARBITT.image },
  { property: 'twitter:url', content: ARBITT.url },
];

export const ARBITT_SEO_METAS = {
  title : ARBITT.title,
  description : ARBITT.description,
  ogTitle : ARBITT.title,
  ogDescription: ARBITT.description,
  ogImage: ARBITT.image,
  twitterCard : "summary_large_image",
  ogUrl : ARBITT.url,
  ogType : "website",
};
