export const state = {
  excluded_users: [],
  query_search: '',
  
  excluded_types: [],
  excluded_types_qp : null
};


export const mutations = {
  SET_EXCLUDED_USERS(state, users) {
    state.excluded_users = users;
  },
  SET_QUERY_SEARCH(state, query_search) {
    state.query_search = query_search;
  },

  SET_EXCLUDED_TYPES(state, types) {
    state.excluded_types = types;
  },
  SET_EXCLUDED_TYPES_QP(state, types) {
    state.excluded_types_qp = types;
  }
};

export const actions = {
  updateExcludedUsers({ commit },  users) {
    commit('SET_EXCLUDED_USERS', users);
  },
  updateQuerySearch({ commit },  query_search) {
    commit('SET_QUERY_SEARCH', query_search);
  },

  updateExcludedTypes({ commit },  types) {
    commit('SET_EXCLUDED_TYPES', types);
  },
  updateExcludedTypesQP({ commit },  types) {
    commit('SET_EXCLUDED_TYPES_QP', types);
  }
};

export const getters = {
  getExcludedUsers: (state) => () => { return state.excluded_users },
  getExcludedTypes: (state) => () => { return state.excluded_types },
  getQuerySearch: (state) => () => { return state.query_search },
  getExcludedTypesQP: (state) => () => { return state.excluded_types_qp }
};