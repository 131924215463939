<script setup>

// libraries
import { onMounted, provide } from 'vue';
import { useStore } from 'vuex';
import { useSeoMeta,useHead } from '@unhead/vue'
//components

// variables
import {
  ARBITT_SEO_METAS,
  ARBITT
} from "@/utils/metas.js";
const store = useStore();
// const subdom = ref (store.getters['subdom/subdomData']());
// functions

// lifecycle hooks

onMounted(() => {
  document.body.setAttribute('data-pc-sidebar-theme', "dark");
    const sessionData = localStorage.getItem('session') || sessionStorage.getItem('session')
    if (sessionData) {
      const sessionDataParsed = JSON.parse(sessionData)
      store.dispatch('session/setSessionData', sessionDataParsed)
    }
});

useHead({
  title: ARBITT_SEO_METAS.title, // El título dinámico
  meta: [
    { name: 'description', content: ARBITT.description },
    { property: 'og:site_name', content: ARBITT.site_name },
    { property: 'og:url', content: ARBITT.url },
    { property: 'og:title', content: ARBITT.title },
    { property: 'og:image', content: "https://" + process.env.VUE_APP_API_DOMAIN + "public/images/arbitt-logo-white.jpg" }, // Requiere explícito
    { property: 'og:description', content: ARBITT.description },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:url', content: ARBITT.url },
    { property: 'twitter:title', content: ARBITT.title },
    { property: 'twitter:description', content: ARBITT.description },
    { property: 'twitter:image', content: "https://" + process.env.VUE_APP_API_DOMAIN + "public/images/arbitt-logo-white.jpg" }
  ],
});

useSeoMeta({
  title: ARBITT_SEO_METAS.title,
  ogTitle: ARBITT_SEO_METAS.title,
  description: ARBITT_SEO_METAS.description,
  ogImage: "https://" + process.env.VUE_APP_API_DOMAIN + "public/images/arbitt-logo-white.jpg",
});


// watchers
provide('subdom',
  store.getters['subdom/subdomData']()
);
</script>


<template>
  <router-view v-slot="{ Component }">
    <Transition name="fade-slow" mode="out-in">
      <div>
        <component :is="Component" />
      </div>
    </Transition>
  </router-view>
</template>
